import './app.scss';
import 'lazysizes';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const accordionSections = document.querySelectorAll('.faq-accordion') as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        import('./ts/accordion').then((faq) => {
            accordionSections.forEach(function (section) {
                faq.default.init(section);
            });
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    const kpiSections = document.querySelectorAll('.kpis') as NodeListOf<HTMLElement>;
    if (kpiSections.length) {
        import('./ts/kpis-increase-numbers').then((kpiIncreaseNumbers) => {
            kpiSections.forEach((container) => {
                kpiIncreaseNumbers.default.init(container);
            });
        });
    }

    const masonryGridSections = document.querySelectorAll('.masonry-grid-section') as NodeListOf<HTMLElement>;
    if (masonryGridSections.length) {
        import('./ts/masonry-grid').then((grid) => {
            masonryGridSections.forEach((container) => {
                grid.default.init(container);
            });
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
            });
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    const heroHomeSections = document.querySelectorAll('.hero-home') as NodeListOf<HTMLElement>;
    if (heroHomeSections.length) {
        import('./ts/hero-home-text-split').then((heroHomeTextSplit) => {
            heroHomeSections.forEach((section) => {
                setTimeout(function () {
                    heroHomeTextSplit.default.init(section);
                }, 300);
            });
        });
        import('./ts/hero-home-intro').then((heroHomeIntro) => {
            heroHomeSections.forEach((section) => {
                setTimeout(function () {
                    heroHomeIntro.default.init(section);
                }, 300);
            });
        });
    }

    // no element request because element will be generated via js, only used once
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const logoBanners = document.querySelectorAll('.logo-slider') as NodeListOf<HTMLElement>;
    if (logoBanners.length) {
        logoBanners.forEach((component) => {
            import('./ts/logo-slider').then((slidingBanner) => {
                slidingBanner.default.init(component);
            });
        });
    }
})();
